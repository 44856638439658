import { Store } from 'antd/lib/form/interface';
import { FormInstance as FormInstanceLib } from 'antd/lib/form';

export type Maybe<T> = T | null;

export interface FormValues extends Store {}

export interface FormInstance extends FormInstanceLib {}

export interface FormProps {
  form?: FormInstance;
  mode?: CreateUpdateMode;
  initialValues?: FormValues;
  isSubmitting?: boolean;
  onSubmit?: (data: FormValues) => void;
}

export interface Option {
  label: string;
  value: string | number | boolean | null | undefined;
  audiences?: Array<Maybe<Audience>> | undefined;
  disabled?: boolean;
  index?: number;
}
export interface Audience {
  id: number;
  title: string;
}

export enum Language {
  EN = 'en',
  SV = 'sv',
}

export enum HorizonalImgAlign {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export enum VerticalImgAlign {
  TOP = 'top',
  CENTER = 'center',
  BOTTOM = 'bottom',
}

export type CreateUpdateMode = 'create' | 'update';
