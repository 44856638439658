import React, { Suspense } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  RouteProps,
} from 'react-router-dom';
import moment from 'moment';
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';

import CommonLayout from './components/CommonLayout';
import PureLayout from 'components/PureLayout';

const SignIn = React.lazy(() => import('components/SignIn'));
const Subjects = React.lazy(() => import('components/Subjects'));
const Articles = React.lazy(() => import('components/Articles'));
const CreateArticle = React.lazy(() => import('components/CreateArticle'));
const UpdateArticle = React.lazy(() => import('components/UpdateArticle'));
const ServiceSubjects = React.lazy(() => import('components/ServiceSubjects'));
const Guides = React.lazy(() => import('components/Guides'));
const CreateGuide = React.lazy(() => import('components/CreateGuide'));
const UpdateGuide = React.lazy(() => import('components/UpdateGuide'));
const SortSubjects = React.lazy(() => import('components/SortSubjects'));
const VideoNotFound = React.lazy(() => import('components/VideoNotFound'));
const SortServiceSubjects = React.lazy(() => import('components/SortServiceSubjects'));
const Services = React.lazy(() => import('components/Services'));

// Monday is the first day of the week
moment.updateLocale('en', {
  week: {
    dow: 1,
    doy: 4,
  },
});

const GET_AUTHORIZED = gql`
  query IsAuthorized {
    isAuthorized @client
  }
`;

const GET_SHARED_DATA = gql`
  query SharedData {
    enAudiences: getAudiences(language: "en") {
      id
      title
      language
    }
    svAudiences: getAudiences(language: "sv") {
      id
      title
      language
    }
  }
`;

interface PrivatRouteProp extends RouteProps {
  allow?: boolean;
  redirect?: string;
}

const CustomRoute = ({ children, allow = true, redirect, ...rest }: PrivatRouteProp) => {
  const routeComponent = () =>
    allow ? children : <Redirect to={{ pathname: redirect || '/login' }} />;
  return <Route {...rest} render={routeComponent} />;
};

const Routes = () => {
  const { data } = useQuery(GET_AUTHORIZED);
  useQuery(GET_SHARED_DATA);

  return (
    <Router>
      <Switch>
        <Route path="/video-not-found">
          <Suspense fallback={<div>Loading...</div>}>
            <VideoNotFound />
          </Suspense>
        </Route>
        <CustomRoute exact path="/login" redirect="/subjects" allow={!data?.isAuthorized}>
          <PureLayout>
            <Suspense fallback={<div>Loading...</div>}>
              <SignIn />
            </Suspense>
          </PureLayout>
        </CustomRoute>
        <CustomRoute path="/" allow={Boolean(data?.isAuthorized)}>
          <CommonLayout>
            <Switch>
              <Route exact path="/">
                <Redirect to="/subjects" />
              </Route>
              <Route exact path="/subjects">
                <Suspense fallback={<div>Loading...</div>}>
                  <Subjects />
                </Suspense>
              </Route>
              <Route exact path="/subjects/sort">
                <Suspense fallback={<div>Loading...</div>}>
                  <SortSubjects />
                </Suspense>
              </Route>
              <Switch>
                <Route exact path="/guides">
                  <Suspense fallback={<div>Loading...</div>}>
                    <Guides />
                  </Suspense>
                </Route>
                <Route exact path="/guides/create">
                  <Suspense fallback={<div>Loading...</div>}>
                    <CreateGuide />
                  </Suspense>
                </Route>
                <Route path="/guides/:slug">
                  <Suspense fallback={<div>Loading...</div>}>
                    <UpdateGuide />
                  </Suspense>
                </Route>
                <Route exact path="/articles">
                  <Suspense fallback={<div>Loading...</div>}>
                    <Articles />
                  </Suspense>
                </Route>
                <Route exact path="/articles/create">
                  <Suspense fallback={<div>Loading...</div>}>
                    <CreateArticle />
                  </Suspense>
                </Route>
                <Route path="/articles/:slug">
                  <Suspense fallback={<div>Loading...</div>}>
                    <UpdateArticle />
                  </Suspense>
                </Route>
                <Route exact path="/service-subjects">
                  <Suspense fallback={<div>Loading...</div>}>
                    <ServiceSubjects />
                  </Suspense>
                </Route>
                <Route path="/service-subjects/sort">
                  <Suspense fallback={<div>Loading...</div>}>
                    <SortServiceSubjects />
                  </Suspense>
                </Route>
                <Route exact path="/services">
                  <Suspense fallback={<div>Loading...</div>}>
                    <Services />
                  </Suspense>
                </Route>
              </Switch>
            </Switch>
          </CommonLayout>
        </CustomRoute>
      </Switch>
    </Router>
  );
};

export default Routes;
